import React from "react";
import Slider from "react-slick";
import TrophyIcon from "../images/trophy-icon.svg";


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none"}}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none"}}
      onClick={onClick}
    />
  );
}

export default function TestimonialSlider() {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    // cssEase: "linear"
  };
  return (
    <div className="story-block-inner bg-white  py-5 shadow-lg rounded-3">
      <Slider {...settings}>
          <div className="story-block px-5">
            <div className="row">
              <div className="story-photo col-sm-12 col-md-12 col-lg-3" style={{height:'136px'}}>
                  <img className='w-100 h-100 rounded-3'style={{objectFit:'cover'}} src={require('../images/slider/slider-1.jpg')} alt="Image"/>
              </div>
              <div className="story-block-testimonial col-sm-12 col-md-12 col-lg-7">
                  <h4 className="mb-4">Arthur C.</h4>
                  <p style={{fontSize: 14}}>“I’m hoping to buy a new television, but I have to convince my wife first!”</p>
              </div>
              <div className="story-block-stats col-sm-12 col-md-12 col-lg-2">
                <div className="d-flex align-items-center">
                  <img className='me-2' src={TrophyIcon} alt="trophy-icon"/>
                  <p className="m-0">Won £2,000</p>
                </div>
              </div>
            </div>
          </div>
          <div className="story-block px-5">
            <div className="row">
              <div className="story-photo col-sm-12 col-md-12 col-lg-3" style={{height:'136px'}}>
                  <img className='w-100 h-100 rounded-3'style={{objectFit:'cover'}} src={require('../images/slider/slider-2.jpg')} alt="Image"/>
              </div>
              <div className="story-block-testimonial col-sm-12 col-md-12 col-lg-7">
                  <h4 className="mb-4">Chris C.</h4>
                  <p style={{fontSize: 14}}>“Palace for Life is an amazing charity that does some much locally so I will continue to support every home game. Looking forward to spoiling the kids with the winnings!”</p>
              </div>
              <div className="story-block-stats col-sm-12 col-md-12 col-lg-2">
                <div className="d-flex align-items-center">
                  <img className='me-2' src={TrophyIcon} alt="trophy-icon"/>
                  <p className="m-0">Won £2,000</p>
                </div>
              </div>
            </div>
          </div>
          <div className="story-block px-5">
            <div className="row">
              <div className="story-photo col-sm-12 col-md-12 col-lg-3" style={{height:'136px'}}>
                  <img className='w-100 h-100 rounded-3'style={{objectFit:'cover'}} src={require('../images/slider/slider-3.jpg')} alt="Image"/>
              </div>
              <div className="story-block-testimonial col-sm-12 col-md-12 col-lg-7">
                  <h4 className="mb-4">Scott H.</h4>
                  <p style={{fontSize: 14}}>“I would encourage all fans to buy a ticket, it's for a great cause and who knows they could be a Jackpot winner too!”</p>
              </div>
              <div className="story-block-stats col-sm-12 col-md-12 col-lg-2">
                <div className="d-flex align-items-center">
                  <img className='me-2' src={TrophyIcon} alt="trophy-icon"/>
                  <p className="m-0">Won £2,000</p>
                </div>
              </div>
            </div>
          </div>
          <div className="story-block px-5">
            <div className="row">
              <div className="story-photo col-sm-12 col-md-12 col-lg-3" style={{height:'136px'}}>
                  <img className='w-100 h-100 rounded-3'style={{objectFit:'cover'}} src={require('../images/slider/slider-4.jpg')} alt="Image"/>
              </div>
              <div className="story-block-testimonial col-sm-12 col-md-12 col-lg-7">
                  <h4 className="mb-4">Wayne and Grace M.</h4>
                  <p style={{fontSize: 14}}>“We had a thoroughly enjoyable experience with the squad at the training ground. Everyone was a credit to Crystal Palace Football club and we are proud to be part of the club as season ticket holders for many years”</p>
              </div>
              <div className="story-block-stats col-sm-12 col-md-12 col-lg-2">
                <div className="d-flex align-items-center">
                  <img className='me-2' src={TrophyIcon} alt="trophy-icon"/>
                  <p className="m-0">Won £2,000</p>
                </div>
              </div>
            </div>
          </div>
          <div className="story-block px-5">
            <div className="row">
              <div className="story-photo col-sm-12 col-md-12 col-lg-3" style={{height:'136px'}}>
                  <img className='w-100 h-100 rounded-3'style={{objectFit:'cover'}} src={require('../images/slider/slider-5.jpg')} alt="Image"/>
              </div>
              <div className="story-block-testimonial col-sm-12 col-md-12 col-lg-7">
                  <h4 className="mb-4">Jack N.</h4>
                  <p style={{fontSize: 14}}>“Palace for Life is an amazing charity that does some much locally so I will continue to support every home game. Looking forward to spoiling the kids with the winnings!”</p>
              </div>
              <div className="story-block-stats col-sm-12 col-md-12 col-lg-2">
                <div className="d-flex align-items-center">
                  <img className='me-2' src={TrophyIcon} alt="trophy-icon"/>
                  <p className="m-0">Won £2,000</p>
                </div>
              </div>
            </div>
          </div>
          <div className="story-block px-5">
            <div className="row">
              <div className="story-photo col-sm-12 col-md-12 col-lg-3" style={{height:'136px'}}>
                  <img className='w-100 h-100 rounded-3'style={{objectFit:'cover'}} src={require('../images/slider/slider-6.jpg')} alt="Image"/>
              </div>
              <div className="story-block-testimonial col-sm-12 col-md-12 col-lg-7">
                  <h4 className="mb-4">Les S.</h4>
                  <p style={{fontSize: 14}}>“I’m from around this area and there’s lots of kids that need that extra support, so I’m happy that we can help by playing the Super Draw”</p>
              </div>
              <div className="story-block-stats col-sm-12 col-md-12 col-lg-2">
                <div className="d-flex align-items-center">
                  <img className='me-2' src={TrophyIcon} alt="trophy-icon"/>
                  <p className="m-0">Won £2,000</p>
                </div>
              </div>
            </div>
          </div>
          <div className="story-block px-5">
            <div className="row">
              <div className="story-photo col-sm-12 col-md-12 col-lg-3" style={{height:'136px'}}>
                  <img className='w-100 h-100 rounded-3'style={{objectFit:'cover'}} src={require('../images/slider/slider-7.jpg')} alt="Image"/>
              </div>
              <div className="story-block-testimonial col-sm-12 col-md-12 col-lg-7">
                  <h4 className="mb-4">Lee D.</h4>
                  <p style={{fontSize: 14}}>“I think that the Palace for Life foundation is a fantastic charity, and I will continue to support the charity at every game i go to.”</p>
              </div>
              <div className="story-block-stats col-sm-12 col-md-12 col-lg-2">
                <div className="d-flex align-items-center">
                  <img className='me-2' src={TrophyIcon} alt="trophy-icon"/>
                  <p className="m-0">Won £2,000</p>
                </div>
              </div>
            </div>
          </div>   
      </Slider>
    </div>
  );
}
