import React from 'react'
import DashboardHeader from '../components/dashboard-header'
import Footer from '../components/Footer'
import AccountImpactBg from '../images/account-impact-top_bg.svg'
import LogoFoundationGray from '../images/logo-foundation-gray.svg'
import AccountImpactBottomDotIcon from '../images/account-impact_bottom_dot.svg'
import useDocumentTitle from '../components/useDocumentTitle'

export default function Account() {
    useDocumentTitle('My Account');
  return (
    <>
    <DashboardHeader active='account'/>
    
    <div className='account-impact-section font-face-sh mb-5 pb-5'>
        <div className='account-impact-container mx-auto position-relative bg-white' style={{maxWidth: 375, borderRadius: '24px 24px 12px 12px', boxShadow: '0 16px 24px rgba(0,0,0,.12)'}}>
            <div className='account-impact-top_bg position-absolute start-0 end-0 top-0 bottom-0' style={{overflow: 'hidden', borderRadius: '24px 24px 0 0'}}>
                <img className='position-absolute start-0 end-0' src={AccountImpactBg} style={{top: -30}} />
            </div>
            <div className='position-relative text-center py-5'>
                <h4 className='account-impact_title text-light text-uppercase font-face-sh-bold fs-18'>Total raised:</h4>
                <h2 className='account-impact_raised text-light font-face-sh-bold display-6'>£46,000+</h2>
            </div>
            <div className='account-impact_logo mt-3 text-center'>
                <img className='mx-auto' src={LogoFoundationGray} style={{maxWidth: 171}} />
            </div>
            <div className='account-impact_text pt-3 pb-3 px-3'>
                <p className='text-center' style={{color: '#140a47', fontSize: 15, lineHeight: '20px'}}>With every Super Draw subscription purchased you support Palace for Life Foundation to achieve its mission of helping young south Londoners grow through the power of sport, and inspiring them to find a better path in life, for a better life.</p>
            </div>
            <div className='account-impact_bottom_dot_icon text-center'>
                <img className='mx-auto position-relative' src={AccountImpactBottomDotIcon} style={{width: 92, bottom: -19}} />
            </div>
        </div>
    </div>

    <Footer/>
    </>
  )
}
